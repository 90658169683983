import React, { useContext, useEffect } from "react";

import Layout from "../../components/layout/layout-ecommerce";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { CartProvider } from "use-shopping-cart";
import { purchaseEventGA } from "../../utils/ga-events";
import { purchaseEventFB } from "../../utils/fb-events";
import { myContext } from "../../utils/provider";

const ThankYouPage = ({ location }) => {
  const images = useStaticQuery(graphql`
    query {
      AppBannerImage: file(
        sourceInstanceName: { eq: "images" }
        name: { eq: "app-banner" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 500
            placeholder: BLURRED
            webpOptions: { quality: 30 }
          )
        }
        name
      }
      QrAppImage: file(
        sourceInstanceName: { eq: "images" }
        name: { eq: "qr2googleANDappleStores" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            width: 100
            placeholder: BLURRED
            webpOptions: { quality: 30 }
          )
        }
        name
      }
    }
  `);

  // Function to perform the serverless checkout. We use netlify function for this.
  async function getCheckoutInfo(sessionID) {
    const response = await fetch(
      `${process.env.NETLIFY_FUNCTIONS_URL}checkoutInfo`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionID: sessionID,
        }),
      }
    );

    const checkoutData = await response.json();

    purchaseEventGA(checkoutData.sessionData, checkoutData.lineItemsData);
    purchaseEventFB(checkoutData.sessionData, checkoutData.lineItemsData);
  }

  const context = useContext(myContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionID = params.get("session_id");
    getCheckoutInfo(sessionID);
  });

  const getCurrency = () => {
    if (context.ecommerceDollar) {
      return "USD";
    } else {
      return "EUR";
    }
  };

  return (
    <>
      <CartProvider
        stripe={process.env.STRIPE_PUBLISHABLE_KEY}
        currency={getCurrency()}
      >
        <Layout helmetName="Thank you">
          <Container maxWidth={"lg"} style={{ paddingTop: "100px" }}>
            <Grid
              container
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={3}>
                <CheckCircleOutlineIcon
                  sx={{ fontSize: 80, color: "#FFA100" }}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h3" gutterBottom={true}>
                  Thank you!
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "justify" }}
                  gutterBottom={true}
                >
                  We have succesfully received your order, and you will receive
                  it shortly.
                </Typography>
              </Grid>
              <Grid container>
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  paddingTop={{ xs: 1, sm: 3 }}
                  paddingBottom={{ xs: 1, sm: 3 }}
                  justifyContent={"center"}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FFA100",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    padding={3}
                    height={"100%"}
                    width={"100%"}
                  >
                    {/*  <Box
                      marginTop={3}
                      marginBottom={3}
                      display={{ xs: "none", sm: "flex" }}
                    >
                      <Typography variant="h4" gutterBottom={true}>
                        Enjoy the full potential of the
                      </Typography>
                    </Box>
                    <Box
                      marginTop={3}
                      marginBottom={3}
                      display={{ xs: "flex", sm: "none" }}
                    >
                      <Typography variant="h4" gutterBottom={true}>
                        Enjoy the full potential of&nbsp;the{" "}
                        <span style={{ color: "#F9F9F9", fontWeight: "bold" }}>
                          MicroREC
                        </span>
                      </Typography>
                    </Box> */}
                    <Box marginBottom={3}>
                      <Typography variant="body1" gutterBottom={true}>
                        In the meantime, you can download our MicroREC App here:
                      </Typography>
                    </Box>
                    <Box
                      display={{ xs: "none", sm: "flex" }}
                      sx={{
                        backgroundColor: "#F9F9F9",
                        borderRadius: "5px",
                        width: 150,
                        height: 150,
                      }}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <GatsbyImage
                        image={getImage(images.QrAppImage)}
                        fadeIn={false}
                        loading="eager"
                        key={images.QrAppImage.name}
                      />
                    </Box>

                    <Box
                      display={{ xs: "flex", sm: "none" }}
                      style={{ justifyContent: "center" }}
                    >
                      <a
                        href="http://onelink.to/nqua9j"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#F9F9F9",
                            color: "black",
                            justifContent: "center",
                          }}
                        >
                          Download MicroREC App
                        </Button>
                      </a>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  paddingTop={{ xs: 1, sm: 3 }}
                  paddingBottom={{ xs: 1, sm: 3 }}
                >
                  <Box
                    sx={{ backgroundColor: "#F9F9F9", borderRadius: "5px" }}
                    padding={3}
                    height={"100%"}
                  >
                    <Grid
                      item
                      marginTop={3}
                      display={{ xs: "none", sm: "flex" }}
                      style={{ justifyContent: "center" }}
                    >
                      <Typography
                        variant="h4"
                        color={"#FFA100"}
                        gutterBottom={true}
                        sx={{ fontWeight: "bold" }}
                      >
                        MicroREC
                      </Typography>
                    </Grid>
                    <GatsbyImage
                      image={getImage(images.AppBannerImage)}
                      fadeIn={false}
                      loading="eager"
                      key={images.AppBannerImage.name}
                    />{" "}
                  </Box>
                </Grid>{" "}
                <Grid xs={12} style={{ marginTop: "5%" }}>
                  <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfzxJZVTQbPbEVA5zDzhmak0p-jnhh4OSq62LxQ_0THY6P-pA/viewform?embedded=true"
                    width="100%"
                    height="700"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                  >
                    Loading…
                  </iframe>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </CartProvider>
    </>
  );
};

export default ThankYouPage;
